<template>
    <div v-if="isModalLoaded" class="staff-month__settings settings settings__chief">
        <button type="button" class="btn-block-option close-modal" @click="$modal.hide('ChiefModal')"> ×
        </button>
        <div class="settings__chief">
            <header class="settings__header">
                <div class="settings__meta">
                    <div class="settings__header-wrap">
                        <div class="settings__header-row">
                            <h2 class="h2 settings__title">Сотрудники месяца отдела {{currentStruct.name}}</h2>
                            <div class="settings__header-months">
                                <a
                                    href="javascript:void(0)"
                                    class="settings__header-month"
                                    :class="{'settings__header-month--active':isCurrentMonth}"
                                    @click="isCurrentMonth = true"
                                >
                                    {{MONTHS_NAMES[currentMonth]}}
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    class="settings__header-month"
                                    :class="{'settings__header-month--active':!isCurrentMonth}"
                                    @click="isCurrentMonth = false"
                                >
                                    {{MONTHS_NAMES[prevMonth]}}
                                </a>
                            </div>
                        </div>
                        <span class="settings__max hidden">Максимум 1 сотрудник</span>
                    </div>
                    <div class="settings__header-tabs">

                    </div>
                </div>
            </header>

            <main class="settings__body settings__body--desktop">
                <div class="settings__block settings__staff">
                    <div class="settings__subheader">
                        <fragment v-if="allStaff.length > 1">
                            <span class="settings__select-title">Выберите отдел</span>
                            <v-select2
                                v-model="currentStruct"
                                :options="allStaff"
                                label="name"
                            >
                                <span slot="no-options">Ничего не найдено</span>
                            </v-select2>
                        </fragment>
                        <h3 v-else class="h3 settings__subheader-title">
                            Сотрудники отдела {{currentStruct.users.length}}
                        </h3>
                    </div>
                    <div class="settings__block-wrapper">
                        <div class="settings__search">
                            <input type="search" v-model="searchValue" class="settings__search-input"
                                   placeholder="Поиск">
                        </div>
                        <div class="settings__tab-list">
                            <ul
                                class="settings__list settings__list--total"
                            >
                                <span v-if="!currentStruct.users.length" class="settings__list-empty">
                                    В данном отделе пока нет сотрудников.
                                </span>
                                <li
                                    v-for="user in searchValue.length ? currentStruct.users.filter(item => item.full_name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) : currentStruct.users"
                                    class="settings__user"
                                    :class="{'settings__user--selected': selectedUsersId.includes(user.id)}"
                                >
                                    <div class="settings__user-info ml-0">
                                        <router-link
                                            :to="user.link"
                                            class="settings__user-title"
                                        >
                                            {{user.short_name}}
                                        </router-link>
                                    </div>
                                    <button
                                        class="settings__user-btn settings__user-add"
                                        title="Добавить"
                                        @click="addUser(user)"
                                        :disabled="selectedUsersId.includes(user.id)"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="settings__block settings__selected">
                    <div class="settings__subheader">
                        <div class="settings__subheader-tabs">
                            <div class="settings__subheader-tab">
                                <h3 class="h3 settings__subheader-title">Выбраны</h3>
                            </div>
                        </div>
                    </div>
                    <ul class="settings__list settings__selected-list">
                        <selected-user
                            v-for="user in selectedUsers[month].structure.find(struct => struct.id === currentStruct.id).users"
                            :key="currentStruct.id+month+user.id"
                            :user="user"
                            :on-remove="removeUser"
                            :on-save="saveMerit"
                        />
                    </ul>
                </div>
            </main>

            <!-- Слайдер для мобильных устройств -->
            <main class="settings__body settings__body--mobile">
                <swiper class="settings__slider" :options="swiperOptions">
                    <swiper-slide class="settings__slider-item">
                        <h3 @click="currentTab = 1" class="h3 settings__slider-link"
                            :class="{'settings__slider-link--active':currentTab === 1}">Все сотрудники</h3>
                    </swiper-slide>
                    <swiper-slide class="settings__slider-item">
                        <h3 @click="currentTab = 2" class="h3 settings__slider-link"
                            :class="{'settings__slider-link--active':currentTab === 2}">Выбраны</h3>
                    </swiper-slide>
                </swiper>
                <div v-if="currentTab !== 3" class="settings__search">
                    <input type="search" v-model="searchValue" class="settings__search-input"
                           placeholder="Поиск">
                </div>
                <div class="settings__slider-wrap">
                    <ul class="settings__list settings__list--total" v-if="currentTab === 1">
                        <span v-if="!currentStruct.users.length" class="settings__list-empty">
                            В данном отделе пока нет сотрудников.
                        </span>
                        <span class="settings__select-title">Выберите отдел</span>
                        <v-select2
                            v-model="currentStruct"
                            :options="allStaff"
                            label="name"
                        >
                            <span slot="no-options">Ничего не найдено</span>
                        </v-select2>
                        <li
                            v-for="user in searchValue.length ? currentStruct.users.filter(item => item.full_name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) : currentStruct.users"
                            class="settings__user"
                            :class="{'settings__user--selected': selectedUsersId.includes(user.id)}"
                        >
                            <div class="settings__user-info ml-0">
                                <router-link
                                    :to="user.link"
                                    class="settings__user-title"
                                >
                                    {{user.short_name}}
                                </router-link>
                            </div>
                            <button
                                class="settings__user-btn settings__user-add"
                                title="Добавить"
                                @click="addUser(user)"
                                :disabled="selectedUsersId.includes(user.id)"
                            />
                        </li>
                    </ul>
                    <ul class="settings__list settings__selected-list" v-if="currentTab === 2">
                        <selected-user
                            v-for="user in selectedUsers[month].structure.find(struct => struct.id === currentStruct.id).users"
                            :key="currentStruct.id+month+user.id"
                            :user="user"
                            :on-remove="removeUser"
                            :on-save="saveMerit"
                        />
                    </ul>
                </div>
            </main>
            <footer class="settings__btns">
                <button class="v-btn v-btn--transparent" @click="$modal.hide('ChiefModal')">Отменить</button>
                <button class="v-btn" @click="saveUser" :disabled="this.isSaving">Сохранить</button>
            </footer>
        </div>

        <div class="settings__chief">

        </div>

    </div>
</template>

<script>
    import session from '@/api/session';
    import {mapState} from "vuex";

    export default {
        name: "ChiefModal",
        components: {
            SelectedUser : () => import('./SelectedUser'),
        },
        data() {
            return {
                currentMonth: 1,
                prevMonth: 1,
                currentStruct: null,
                searchValue: this.searchValue || '',
                selectedUsers: {
                    currentMonth: {
                        number: 0,
                        structure: []
                    },
                    prevMonth: {
                        number: 0,
                        structure: []
                    }
                },
                isCurrentMonth: false,
                isSaving: false,
                isModalLoaded: false,
                currentTab: 1,
                swiperOptions: {
                    slidesPerView: 'auto',
                    containerModifierClass: 'settings__slider-',
                    slideClass: 'settings__slide',
                    slideActiveClass: 'settings__slide--active'
                },
            }
        },
        async created() {
            let month = new Date().getMonth();
            this.currentMonth = month + 1;
            if (month === 0) {
                this.prevMonth = 12
            } else {
                this.prevMonth = month
            }
            this.selectedUsers.currentMonth.number = this.currentMonth;
            this.selectedUsers.prevMonth.number = this.prevMonth;

            try {
                await this.$store.dispatch('staff_month/setAllStaff');
                await this.$store.dispatch('staff_month/setRecommendationStaff');

                /* Все сотрудниики */
                if (this.allStaff.length) {
                    this.allStaff.forEach(interim => {
                        this.selectedUsers.currentMonth.structure.push({
                            id: interim.id,
                            users: []
                        });
                        this.selectedUsers.prevMonth.structure.push({
                            id: interim.id,
                            users: []
                        });
                    });
                    this.currentStruct = this.allStaff[0];
                }
                /* Список сохранённых рекоммендаций текущего начальника */
                if (this.recommendationStaff.length) {
                    this.recommendationStaff.forEach(year => {
                        year.months.forEach(month => {
                            const isCurrentMonth = month.month === this.currentMonth;
                            const selectedMonth = this.selectedUsers[isCurrentMonth ? 'currentMonth' : 'prevMonth'];
                            month.interim.forEach(interim => {
                                const struct = selectedMonth.structure.find(struct => struct.id === interim.id);
                                struct.users.push(...interim.users)
                            });
                        })
                    });
                }
                this.isModalLoaded = true;
            } catch (error) {
                this.toastError('Произошла непредвиденная ошибка на сервере. Обратитесь к администрации');
                this.$modal.hide('AdminModal');
                console.error(error);
            }
        },
        computed: {
            month() {
                return this.isCurrentMonth ? 'currentMonth' : 'prevMonth';
            },
            selectedStruct() {
                return this.selectedUsers[this.month].structure.find(struct => struct.id === this.currentStruct.id)
            },
            selectedUsersId() {
                const idList = [];
                this.selectedStruct.users.map(user => {
                    idList.push(user.id);
                });
                return idList;
            },
            ...mapState('staff_month', [
                'allStaff', 'recommendationStaff'
            ]),
            ...mapState('default_data', [
                'current_user', 'MONTHS_NAMES', 'error_handler'
            ])
        },
        methods: {
            addUser(user) {
                this.selectedStruct.users.push({...user});
            },
            removeUser(user) {
                this.selectedStruct.users = this.selectedStruct.users.filter(structUser => structUser.id !== user.id)
            },
            saveMerit(userId, merit = '') {
                const user = this.selectedStruct.users.find(user => user.id === userId);
                user.merit = merit;
            },
            async saveUser() {
                /* К текущему месяцу прибавляем 1, так как в Python месяца начинаются с 1
                * А в JS с нуля */
                this.isSaving = true;
                let date = new Date();
                const data = [
                    {
                        year: date.getFullYear(),
                        months: [
                            {
                                //Текущий месяц
                                number: this.currentMonth,
                                structure: this.selectedUsers.currentMonth.structure.map(struct => {
                                    return {
                                        id: struct.id,
                                        users: struct.users.map(user => {
                                            if (!user.merit) {
                                                delete user.merit;
                                            }
                                            return {
                                                id: user.id,
                                                merit: user.merit
                                            }
                                        })
                                    }
                                })
                            },
                            {
                                //Предыдущий месяц
                                number: this.prevMonth,
                                structure: this.selectedUsers.prevMonth.structure.map(struct => {
                                    return {
                                        id: struct.id,
                                        users: struct.users.map(user => {
                                            if (!user.merit) {
                                                delete user.merit;
                                            }
                                            return {
                                                id: user.id,
                                                merit: user.merit
                                            }
                                        })
                                    }
                                })
                            }
                        ]
                    }
                ];
                try {
                    await session.post(`/api/v1/employees-month/`, data);
                    this.isSaving = false;
                    this.$modal.hide('ChiefModal');
                } catch (error) {
                    this.toastError('Произошла ошибка на сервере');
                    this.isSaving = false;
                    console.error(error)
                }
            },
            toastError(text, timer = 3000) {
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    title: text,
                    icon: "error",
                    showConfirmButton: false,
                    timer: timer,
                    timerProgressBar: true,
                    onOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer);
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                    }
                });
            }
        },
    }
</script>

<style lang="sass">
    @import "#sass/dashboard/staff-month_settings"

    .settings
        &__max
            margin:
                top: 10px
                left: 0px

        .vs__clear
            display: none !important

        @media (max-width: 500px)
            &__chief
                .swiper-wrapper
                justify-content: space-around !important

                .settings__slider
                    &-item,
                    &-link
                        width: fit-content !important


</style>
<style lang="scss">
    .v--modal-overlay[data-modal="ChiefModal"] {
        .v--modal-box {
            max-width: 900px !important;
            margin: 0 auto;
        }
    }
</style>
